import { ItemCategorySelect } from "./components/item-category-select";
import { ItemRaritySelect } from "./components/item-rarity-select";
import { SocketsSelect } from "./components/sockets-select";
import { ClassSelect } from "./components/class-select";
import { AuctionTypeSelect } from "./components/auction-type-select";
import { ListPeriodSelect } from "./components/list-period-select";
import { PriceRangeInput } from "./components/price-range-input";
import { ItemPowerRangeInput } from "./components/item-power-range-input";
import { LevelRequirementRangeInput } from "./components/level-requirement-range-input";

export function GeneralFilters({
  children,
}: React.PropsWithChildren): JSX.Element {
  return <div>{children}</div>;
}

GeneralFilters.ItemCategorySelect = ItemCategorySelect;
GeneralFilters.ItemRaritySelect = ItemRaritySelect;
GeneralFilters.SocketsSelect = SocketsSelect;
GeneralFilters.ClassSelect = ClassSelect;
GeneralFilters.AuctionTypeSelect = AuctionTypeSelect;
GeneralFilters.ListPeriodSelect = ListPeriodSelect;
GeneralFilters.PriceRangeInput = PriceRangeInput;
GeneralFilters.ItemPowerRangeInput = ItemPowerRangeInput;
GeneralFilters.LevelRequirementRangeInput = LevelRequirementRangeInput;
