import { Controller, useFormContext } from "react-hook-form";
import { GameplayModeSelect as Select } from "@/components/gameplay-mode-select";
import type { GamplayModeOption, GamePlayMode } from "@/types";

function GameplayModeSelect({
  options,
}: {
  options: GamplayModeOption[];
}): JSX.Element {
  const { setValue } = useFormContext();
  return (
    <Controller
      name="gameplayMode"
      render={({ field }) => {
        return (
          <Select
            onValueChange={(value) => {
              setValue(field.name, value, { shouldValidate: true });
            }}
            options={options}
            value={field.value as GamePlayMode}
          />
        );
      }}
    />
  );
}

export { GameplayModeSelect };
