import { useForm, type UseFormReturn } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Schema, type Values } from "./schema";

function useSearchForm(): UseFormReturn<Values> {
  return useForm<Values>({
    resolver: zodResolver(Schema),
    defaultValues: {
      select1: "",
      gameplayMode: "",
      select3: "",
      itemCategory: "",
      itemRarity: "",
      sockets: "",
      class: "",
      auctionType: "",
      listPeriod: "",
      priceMin: "",
      priceMax: "",
      itemPowerMin: "",
      itemPowerMax: "",
      levelRequirementMin: "",
      levelRequirementMax: "",
      search: "",
      statFilters: [],
    },
    mode: "onChange",
    reValidateMode: "onChange",
    shouldFocusError: false,
  });
}

export { useSearchForm };
