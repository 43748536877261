"use client";
import { useState, useContext, createContext } from "react";
import * as PrimitiveCollapsible from "@/components/collapsible";

interface ContextProps {
  open: boolean;
}
const Context = createContext<ContextProps>({ open: false });

export function Collapsible({
  children,
}: React.PropsWithChildren): JSX.Element {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <PrimitiveCollapsible.Collapsible onOpenChange={setOpen} open={open}>
      <Context.Provider value={{ open }}>{children}</Context.Provider>
    </PrimitiveCollapsible.Collapsible>
  );
}

Collapsible.Trigger = Trigger;
Collapsible.Content = PrimitiveCollapsible.CollapsibleContent;

function Trigger(): JSX.Element {
  const { open } = useContext(Context);
  return (
    <PrimitiveCollapsible.CollapsibleTrigger
      className="h-[23px] w-[105px] bg-[image:url('/images/buttons/button-blue/button-blue-bg.png')] bg-cover px-3 font-diablo text-[10px] uppercase"
      type="button"
    >
      {open ? "Hide" : "Show"} Filters
    </PrimitiveCollapsible.CollapsibleTrigger>
  );
}
