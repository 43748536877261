import { Controller } from "react-hook-form";
import { Field } from "./field";
function LevelRequirementRangeInput(): JSX.Element {
  return (
    <Field
      component={
        <div className="grid grid-cols-2 ">
          <Controller
            name="levelRequirementMin"
            render={({ field }) => {
              return (
                <input
                  className="border border-gray-700 bg-gray-600 px-2 font-diablo text-xs text-gray-50 placeholder:text-gray-50 focus:outline-none"
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    e.target.value = e.target.value.replace(/[^-?\d]/g, "");
                    field.onChange(e);
                  }}
                  placeholder="MIN"
                  value={field.value as string}
                />
              );
            }}
          />

          <Controller
            name="levelRequirementMax"
            render={({ field }) => {
              return (
                <input
                  className="border border-gray-700 bg-gray-600 px-2 font-diablo text-xs text-gray-50 placeholder:text-gray-50 focus:outline-none"
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    e.target.value = e.target.value.replace(/[^-?\d]/g, "");
                    field.onChange(e);
                  }}
                  placeholder="MAX"
                  value={field.value as string}
                />
              );
            }}
          />
        </div>
      }
      label="Level Requirement"
    />
  );
}

export { LevelRequirementRangeInput };
