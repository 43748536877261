import {
  useFormContext,
  useFieldArray,
  type UseFieldArrayAppend,
  type UseFieldArrayRemove,
  type FieldArrayWithId,
} from "react-hook-form";
import { type Values } from "../../schema";
import { StatFilter } from "./stat-filter";
import { AddStatFilterCollapsible } from "./add-stat-filter-collapsible";

interface StatFiltersApi {
  appendStat: UseFieldArrayAppend<Values, "statFilters">;
  removeStat: UseFieldArrayRemove;
  statFilters: FieldArrayWithId<Values, "statFilters">[];
}

export function StatFilters({
  children,
}: {
  children?: React.FunctionComponent<StatFiltersApi>;
}): JSX.Element {
  const { control } = useFormContext<Values>();

  const {
    fields: statFilters,
    append: appendStat,
    remove: removeStat,
  } = useFieldArray<Values, "statFilters">({
    control,
    name: "statFilters",
  });

  return (
    <div>
      {children?.({
        appendStat,
        removeStat,
        statFilters,
      })}
      <Footer>
        <AddStatFilterCollapsible appendStat={appendStat} />
      </Footer>
    </div>
  );
}

StatFilters.Header = Header;
StatFilters.Content = Content;
StatFilters.Footer = Footer;
StatFilters.Title = Title;

StatFilters.StatFilter = StatFilter;

function Header({ children }: React.PropsWithChildren): JSX.Element {
  return <div className="mb-1">{children}</div>;
}

function Content({ children }: React.PropsWithChildren): JSX.Element {
  return <div className="space-y-1">{children}</div>;
}

function Footer({ children }: React.PropsWithChildren): JSX.Element {
  return <div className="mt-1">{children}</div>;
}

function Title(): JSX.Element {
  return (
    <h3 className="mb-2 flex items-center justify-center text-center font-diablo text-sm uppercase">
      Affix Filters
    </h3>
  );
}
