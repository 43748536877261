"use client";

import { createContext, useContext } from "react";
import Link, { type LinkProps } from "next/link";
import Img from "next/image";
import { ScrollArea } from "@/components/scroll-area";

import { cn } from "@repo/ui/utils";
import { ItemRarity, ItemType, ItemSubType } from "@/types";

interface ItemContextType {
  rarity: ItemRarity;
  type: ItemType;
  subType: ItemSubType;
}

const ItemContext = createContext({} as ItemContextType);

export function ActivityList({ children }: React.PropsWithChildren) {
  return (
    <div className="relative flex-1">
      <div className="absolute inset-0 flex h-full min-w-full">
        <ScrollArea className="top-0 max-w-full flex-1 overflow-hidden">
          <div className="items-center justify-center ">
            <div className="grid px-2">{children}</div>
          </div>
        </ScrollArea>
      </div>
    </div>
  );
}

ActivityList.Item = Item;

function Item({
  rarity,
  type,
  subType,

  children,
  ...rest
}: React.PropsWithChildren<
  LinkProps & {
    rarity: ItemRarity;
    type: ItemType;
    subType: ItemSubType;
  }
>) {
  return (
    <ItemContext.Provider value={{ rarity, type, subType }}>
      <Link
        {...rest}
        className="flex h-14 items-center border-b border-b-gray-600 font-diablo"
        scroll={false}
      >
        {children}
      </Link>
    </ItemContext.Provider>
  );
}

Item.Image = Image;
Item.Title = Title;
Item.Badge = Badge;

function Image() {
  const { type, subType } = useContext(ItemContext);
  return (
    <div className="relative mr-1 h-[43px] w-[40px]">
      <Img alt="" fill src={`/assets/${type}/${subType}.webp`} />
    </div>
  );
}

function Title({ children }: React.HTMLAttributes<HTMLElement>) {
  const { rarity } = useContext(ItemContext);
  return (
    <div
      className={cn("flex-1 text-left text-sm text-gray-50", {
        "text-legendary": rarity === "legendary",
        "text-unique": rarity === "unique",
        "text-rare": rarity === "rare",
        "text-magic": rarity === "magic",
      })}
    >
      {children}
    </div>
  );
}

function Badge() {
  return (
    <div className="pr-2">
      <div className="mb-1 ml-auto  flex h-4 w-4 items-center justify-center rounded-full bg-red-300 text-xs">
        1
      </div>
      <div className="text-xs">2hr 21min Left</div>
    </div>
  );
}
