export function Field({
  label,
  component,
}: {
  label: string;
  component: JSX.Element;
}): JSX.Element {
  return (
    <div className="grid grid-cols-2">
      <label className="flex border border-gray-700 px-2 font-diablo text-base">
        {label}
      </label>
      <div className="grid">{component}</div>
    </div>
  );
}
