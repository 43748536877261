import { Controller } from "react-hook-form";

function SearchInput(): JSX.Element {
  return (
    <div className="flex h-[30px] gap-2">
      <Input />
      <Button />
    </div>
  );
}

function Input(): JSX.Element {
  return (
    <Controller
      name="search"
      render={({ field }) => {
        return (
          <input
            className="h-[30px] flex-1 border border-gray-700  bg-gray-800 px-2 font-diablo text-sm placeholder:text-gray-50 focus-visible:outline-none"
            placeholder="Search..."
            type="text"
            {...field}
          />
        );
      }}
    />
  );
}

function Button(): JSX.Element {
  return (
    <button
      className="h-[30px] w-[144px]  bg-[image:url('/images/buttons/button-background-red.png')] bg-cover  font-diablo text-xs uppercase"
      type="submit"
    >
      Search
    </button>
  );
}
export { SearchInput };
