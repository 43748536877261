import { ChevronDown } from "lucide-react";
import { Controller, useFormContext } from "react-hook-form";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectIcon,
  SelectContent,
  SelectGroup,
  SelectItem,
} from "@/components/select";
import { Field } from "./field";
function ListPeriodSelect({
  options,
}: {
  options: { value: string; label: string }[];
}): JSX.Element {
  const { setValue } = useFormContext();
  return (
    <Controller
      name="listPeriod"
      render={({ field }) => {
        return (
          <Field
            component={
              <Select
                onValueChange={(value) => {
                  setValue(field.name, value, { shouldValidate: true });
                }}
                value={field.value as string}
              >
                <SelectTrigger className="py-0">
                  <SelectValue className="px-4" placeholder="Select" />
                  <SelectIcon asChild>
                    <ChevronDown className="h-5 w-5" />
                  </SelectIcon>
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {options.map((option) => {
                      return (
                        <SelectItem
                          className="font-diablo"
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </SelectItem>
                      );
                    })}
                  </SelectGroup>
                </SelectContent>
              </Select>
            }
            label="List Period"
          />
        );
      }}
    />
  );
}

export { ListPeriodSelect };
