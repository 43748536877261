import { useState, type Dispatch, type SetStateAction } from "react";
import { twMerge } from "tailwind-merge";
import {
  useFormContext,
  useWatch,
  type UseFieldArrayAppend,
} from "react-hook-form";
import { ChevronDownIcon } from "lucide-react";
import { Command as Cmd } from "cmdk";
import { Popover, PopoverTrigger, PopoverContent } from "@/components/popover";
import {
  Command,
  CommandGroup,
  CommandEmpty,
  CommandItem,
  CommandList,
} from "@/components/command";
import type { Values } from "../../../../schema";

interface Option {
  label: string;
  value: string;
}

export function AddAffixCombobox({
  appendAffix,
  statIndex,
  options,
}: {
  appendAffix: UseFieldArrayAppend<Values, `statFilters.${number}.affixes`>;
  statIndex: number;
  options: Option[];
}): JSX.Element {
  const { control } = useFormContext<Values>();
  const [open, setOpen] = useState<boolean>(false);

  const affixes = useWatch({
    control,
    name: `statFilters.${statIndex}.affixes`,
  });

  const opts = options.filter((option) => {
    return !affixes.some((affix) => affix.key === option.value);
  });
  return (
    <Popover onOpenChange={setOpen} open={open}>
      <Command>
        <Input setOpen={setOpen} />
        <PopoverContent
          align="start"
          className="w-[var(--radix-popover-trigger-width)] p-0"
          onOpenAutoFocus={(e) => {
            e.preventDefault();
          }}
        >
          <CommandList>
            <CommandEmpty className="text-center font-diablo">
              No items to show
            </CommandEmpty>
            <Options
              appendAffix={appendAffix}
              options={opts}
              setOpen={setOpen}
            />
          </CommandList>
        </PopoverContent>
      </Command>
    </Popover>
  );
}

function Input({
  setOpen,
}: {
  setOpen: Dispatch<SetStateAction<boolean>>;
}): JSX.Element {
  return (
    <div className="h-8">
      <div className="relative h-8 bg-gray-700">
        <PopoverTrigger asChild>
          <Cmd.Input asChild>
            <div className="relative h-8">
              <input
                className={twMerge(
                  "absolute h-8  w-full bg-[transparent] text-center font-diablo placeholder:text-gray-50 focus-visible:outline-none",
                )}
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(true);
                }}
                placeholder="+ ADD AFFIX"
                type="text"
              />
              <ChevronDownIcon className="Z-10 absolute right-2 top-1.5 h-5 w-5 cursor-pointer text-gray-50" />
            </div>
          </Cmd.Input>
        </PopoverTrigger>
      </div>
    </div>
  );
}

function Options({
  appendAffix,
  setOpen,
  options,
}: {
  appendAffix: UseFieldArrayAppend<Values, `statFilters.${number}.affixes`>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  options: Option[];
}): JSX.Element {
  return (
    <CommandGroup className="p-0">
      {options.map((option) => {
        return (
          <CommandItem
            asChild
            className="w-full cursor-pointer border border-gray-700 px-2 font-diablo uppercase"
            key={option.value}
            onSelect={() => {
              appendAffix({
                key: option.value,
                title: option.label,
                min: "",
                max: "",
                enabled: true,
                greaterAffixEnabled: false,
              });
              setOpen(false);
            }}
          >
            <button type="button">{option.label}</button>
          </CommandItem>
        );
      })}
    </CommandGroup>
  );
}
