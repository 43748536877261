import { Controller, useFormContext } from "react-hook-form";
import { ChevronDown } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectIcon,
  SelectValue,
} from "@/components/select";

interface Option {
  value: string;
  label: string;
}

function Select3({ options }: { options: Option[] }): React.ReactNode {
  const { setValue } = useFormContext();
  return (
    <Controller
      name="select3"
      render={({ field }) => {
        return (
          <Select
            onValueChange={(value) => {
              setValue(field.name, value, { shouldValidate: true });
            }}
            value={field.value as string}
          >
            <SelectTrigger>
              <SelectValue className="truncate px-4" placeholder="Select" />
              <SelectIcon asChild>
                <ChevronDown className="h-5 w-5" />
              </SelectIcon>
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {options.map((option) => {
                  return (
                    <SelectItem
                      className="font-diablo"
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>
        );
      }}
    />
  );
}

export { Select3 };
