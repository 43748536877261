import * as z from "zod";

export const Schema = z.object({
  select1: z.string().optional(),
  gameplayMode: z.string().optional(),
  select3: z.string().optional(),
  itemCategory: z.string().optional(),
  itemRarity: z.string().optional(),
  sockets: z.string().optional(),
  class: z.string().optional(),
  auctionType: z.string().optional(),
  listPeriod: z.string().optional(),
  priceMin: z.string().optional(),
  priceMax: z.string().optional(),
  itemPowerMin: z.string().optional(),
  itemPowerMax: z.string().optional(),
  levelRequirementMin: z.string().optional(),
  levelRequirementMax: z.string().optional(),
  search: z.string().optional(),
  statFilters: z.array(
    z.object({
      id: z.string().optional(),
      type: z.string(),
      affixes: z.array(
        z.object({
          title: z.string(),
          enabled: z.boolean(),
          greaterAffixEnabled: z.boolean(),
          key: z.string(),
          id: z.string().optional(),
          min: z.string(),
          max: z.string(),
        }),
      ),
    }),
  ),
});

export type Values = z.infer<typeof Schema>;
